<template>
  <div class="coding-container">
    <SubtitleBody subtitle="Overview" :description="codingData.overview" type="noHeaderImage" />
    <!-- Overview -->
    <div class="body">
      <SubtitleBody :subtitle="codingData.intro.title" :description="codingData.intro.description" />
      <div class="break-down">
        <LanguageGroup
          v-for="(item, index) in codingData.breakdown"
          :key="index"
          :langData="item"
          :title="item.name"
        />
      </div>
      <!-- Break down -->
    </div>
    <!-- Body -->
    <BackToTop />
  </div>
</template>

<script>
import { basicInnerPageMixin } from "@/core/mixins/inner_page_mixin.js";
import { writtenData } from "@/core/mixins/written_data_mixin.js";
import LanguageGroup from "@/components/info/LanguageGroup.vue";
export default {
  mixins: [basicInnerPageMixin, writtenData],
  components: {
    LanguageGroup,
  },
};
</script>

<style scoped>
.coding-container {
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  display: grid;
}
.coding-container > div {
  padding: 0 2rem;
}
.break-down {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 4rem;
}
.body {
  display: grid;
  grid-template-rows: 14rem auto;
}
.right-side {
  width: min-content;
}
.lang-group {
}
.progress-bar {
  width: auto !important;
}
</style>